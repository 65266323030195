

















import Vue from 'vue'
export default Vue.extend({
  name: 'LandingCheckbox',
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: {
      type: [Array, Boolean],
      default: false
    },
    label: {
      required: true,
      type: String
    },
    id: {
      required: true,
      type: String
    },
    name: {
      required: true,
      type: String
    },
    container_class: {
      required: false,
      default: ''
    },
    value: {
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    fontSize: {
      type: String,
      default: '1em'
    },
    v: {
      required: false,
      type: Object
    }
  },
  computed: {
    proxyChecked: {
      get () {
        return this.checked
      },
      set (val) {
        if (this.v) this.v.$touch()
        this.$emit('change', val)
      }
    }
  }
})
